<template>
  <div class="cart-loader">
    <div class="row">
      <div class="col-xl-8 col-lg-8">
        <div class="cart-content p-y-28 p-x-20">
          <BaseOrderLoader
            v-for="item in 3"
            :key="item"
          />
        </div>
      </div>

      <div class="col-xl-4 col-lg-4">
        <div class="total-cart p-y-28 p-x-20">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="m-b-18"
            type="rect"
            :width="140"
            :height="30"
            animation="wave"
          /> -->

          <div class="m-t-20">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="m-b-18"
              type="rect"
              :width="140"
              :height="40"
              animation="wave"
            /> -->

            <div
              v-for="item in 3"
              :key="item"
            >
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="cart-info-width d-flex justify-content-between align-items-center my-3"
                type="rect"
                :width="140"
                :height="30"
                animation="wave"
              /> -->
            </div>
          </div>

          <div class="m-y-16">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              type="rect"
              :width="140"
              :height="25"
              animation="wave"
            /> -->
          </div>

          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="radius-6 cart-info-width"
            type="rect"
            :width="140"
            :height="30"
            animation="wave"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseOrderLoader from '@/components/partials/base-order-card/baseOrderLoader.vue'
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'CartLoader',
  components: {
    BaseOrderLoader
    // VueSkeletonLoader
  },

  data () {
    return {
      offerLoading: true
    }
  }
}
</script>

<style scoped>
.cart-info-width {
  width: 100% !important;
}
</style>
