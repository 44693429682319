<template>
  <div class="cart radius-14 w-100 white-color-bg">
    <template v-if="isLoading">
      <cartLoader />
    </template>

    <template v-else-if="lists.length">
      <div class="row">
        <div class="col-xl-8 col-lg-8">
          <div class="cart-content p-y-28 p-x-20">
            <h3 class="bold-font fs-20 black-color-text m-b-18">
              {{ $t('account.CartContents') }}
            </h3>

            <CartCard
              v-for="(list, index) in lists"
              :key="index"
              :cartList="list"
              @goToOffer="setOfferId"
              @onRemoveCart="removeFromCart(list)"
              @onMoveToFav="moveToFav(list)"
            />
          </div>
        </div>

        <div class="col-xl-4 col-lg-4">
          <div class="total-cart p-y-28 p-x-20">
            <h3 class="bold-font fs-20 black-color-text m-b-18">
              {{ $t('account.totalCart') }}
            </h3>

            <div
              v-if="isLoggedIn"
              class="d-flex justify-content-between align-items-center white-color-bg p-y-6 p-x-8 radius-4"
            >
              <BaseCheckbox
                v-if="userInfo.users_balance != 0"
                input-id="useFromWallet"
                :value="form.useFromWallet"
                :is-checked="form.useFromWallet"
                :label="$t('account.useWallet')"
                label-color-class="main-color-text"
                @toggle="toggle"
              />

              <span class="medium-font fs-14 purple-color-text">
                {{ userInfo.users_balance }}</span>
            </div>

            <div class="m-t-20">
              <h3 class="bold-font fs-20 black-color-text m-b-18">
                {{ $t('account.title2') }}
              </h3>

              <template v-if="isWallet || !isPromoCodeTrue">
                <div>
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span
                      class="medium-font light-gray-color-text fs-14"
                      v-html="cartInfo[0].key"
                    />
                    <span class="medium-font light-gray-color-text fs-14">{{
                      cartInfo[0].value
                    }}</span>
                  </p>
                </div>

                <div>
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span
                      class="medium-font light-gray-color-text fs-14"
                      v-html="cartInfo[1].key"
                    />
                    <span class="medium-font light-gray-color-text fs-14">{{
                      cartInfo[1].value
                    }}</span>
                  </p>
                </div>

                <div v-if="!isWallet && !isPromoCodeSuccess">
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span
                      class="medium-font light-gray-color-text fs-14"
                      v-html="cartInfo[2].key"
                    />
                    <span class="medium-font light-gray-color-text fs-14">{{
                      cartInfo[2].value
                    }}</span>
                  </p>
                </div>

                <div v-if="isWallet || isPromoCodeSuccess">
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span class="medium-font light-gray-color-text fs-14">
                      رصيد المحفظة
                    </span>
                    <span class="medium-font light-gray-color-text fs-14">{{
                      userInfo.users_balance
                    }}</span>
                  </p>
                </div>

                <div v-if="isWallet">
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span class="medium-font light-gray-color-text fs-14">
                      {{ $t('account.totalRequiredAmount') }}
                    </span>

                    <span
                      class="medium-font main-color-text fs-14"
                    >{{ total_requerd_after_using_wallet }}
                      {{ $t('account.egp') }}</span>
                  </p>
                </div>
              </template>

              <template v-else>
                <div
                  v-for="item in cartInfo"
                  :key="item.key"
                >
                  <p
                    class="d-flex justify-content-between align-items-center my-3"
                  >
                    <span
                      class="medium-font light-gray-color-text fs-14"
                      v-html="item.key"
                    />
                    <span class="medium-font light-gray-color-text fs-14">{{
                      item.value
                    }}</span>
                  </p>
                </div>
              </template>
            </div>

            <div
              v-if="!isPromoCodeTrue"
              class="m-y-16"
            >
              <span class="neutral-4-color-text fs-14 main-color-text">{{
                $t('account.haseDisccount')
              }}</span>

              <div class="coupon-block">
                <BaseTextInput
                  :value="form.coupon"
                  input-id="coupon"
                  type="text"
                  :has-error="errors.has('coupon')"
                  :message="errors.get('coupon')"
                  @reset="errors.clear()"
                  @submit="validateData"
                  @update-value="getCouponData"
                />
                <span
                  v-if="!loadVerifyPromoCode"
                  class="couponBtn neutral-4-color-text fs-14 main-color-text radius-10"
                  @click="verifyPromoCode"
                >{{ $t('account.couponBtn') }}</span>

                <span
                  v-if="loadVerifyPromoCode"
                  class="loadBtn"
                >
                  <Loader />
                </span>
              </div>
            </div>

            <base-button
              :text="$t('forms.continue')"
              bg-color-class="main-color-bg"
              is-medium
              class="m-t-20"
              @click.native="goToPayment()"
            />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <EmptyCart />
    </template>

    <!-- Auth modal -->
    <AuthModal
      :openModal="openModal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import InformationIcon from '@/components/icons/InformationIcon.vue'
import Form from '@/services/Form'
import Errors from '@/services/Errors'
import AuthModal from '../../partials/auth-modal/index.vue'
import cartLoader from './cartLoader.vue'
import CartCard from '../../partials/base-order-card/CartIndex.vue'
import EmptyCart from './EmptyCart.vue'
import ToggleWishList from '../../../services/profile'
import Loader from '../../Loader/index.vue'
import CompletePayment from '../../../services/cart'
import { mapGetters } from 'vuex'

export default {
  name: 'Cart',
  components: {
    EmptyCart,
    InformationIcon,
    CartCard,
    AuthModal,
    Loader,
    cartLoader
  },

  data () {
    return {
      form: new Form({
        useFromWallet: false,
        coupon: ''
      }),

      user_balance: '',
      openModal: false,
      ConfirmModal: false,
      payload: {},
      loading: true,
      isWallet: false,
      isPromoCodeSuccess: false,
      total_requerd_after_using_wallet: '',
      total_requerd_after_using_discount: '',
      errors: new Errors()
    }
  },

  computed: {
    lists: {
      get () {
        return this.$store.state.cart.lists
      },
      set (value) {
        this.$store.commit('cart/Get_Cart_List', value)
      }
    },

    cartInfo: {
      get () {
        return this.$store.state.cart.cartInfo
      },
      set (value) {
        this.$store.commit('cart/Get_User_Cart_Info', value)
      }
    },

    ...mapGetters('Globals', ['userInfo', 'userPhone']),

    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    },

    isPromoCode () {
      return this.$store.state.cart.isPromoCode
    },

    isPromoCodeTrue () {
      return this.$store.state.cart.isPromoCodeTrue
    },

    buyAsAGift () {
      return this.$store.state.cart.buyAsAGift
    },

    cartInfoLength () {
      this.$store.state.cart.cartInfoLength
    },

    isLoading () {
      return this.$store.state.cart.loading
    },

    loadVerifyPromoCode () {
      return this.$store.state.cart.isLoadVerifyPromoCode
    }
  },

  created () {
    this.getAllLists()
    this.$store.dispatch('Globals/getUserInfo')
    // this.cartInfo
  },

  methods: {
    closeModal () {
      this.openModal = false
    },

    verifyPromoCode () {
      if (!this.isLoggedIn) {
        this.openModal = true
      } else {
        const payload = {
          app_version: '',
          store: '',
          device_token: this.getUserIp,
          discount_code: this.form.coupon,
          token: this.getUserToken
        }

        this.$store
          .dispatch('cart/verifyPromoCode', payload)
          .then((res) => {
            if ((is_promo_code = false)) {
            } else {
              this.isPromoCodeSuccess = true
              this.isWallet = false
            }
          })
          .catch(() => {})
      }
      const payload = {
        app_version: '',
        store: '',
        device_token: this.getUserIp,
        discount_code: this.form.coupon,
        token: this.getUserToken
      }

      this.loadVerifyPromoCode = true

      this.$store
        .dispatch('cart/verifyPromoCode', payload)
        .then(() => {
          this.loadVerifyPromoCode = false
        })
        .catch(() => {})
    },

    getCouponData (val) {
      this.form.coupon = val.value
    },

    getAllLists () {
      this.payload = {
        app_version: '',
        store: '',
        device_token: this.getUserIp
      }
      if (this.isLoggedIn) {
        this.payload = {
          ...this.payload,
          token: this.getUserToken
        }
      } else {
        this.payload = {
          ...this.payload
        }
      }
      this.$store
        .dispatch('cart/getAllCarts', this.payload)
        .then(() => {})
        .catch(() => {})
    },

    payWithWalletInfo () {
      const payload = {
        app_version: '',
        store: '',
        device_token: this.getUserIp,
        discount_code: this.form.coupon || null,
        token: this.getUserToken
      }
      this.$store
        .dispatch('cart/payWithWalletInfo', payload)
        .then(() => {})
        .catch(() => {})
    },

    removeFromCart (cartItem) {
      const payload = {
        device_token: this.getUserIp,
        offer_id: cartItem.offer.offer_id,
        type_id: cartItem.type_id
      }

      this.$store.dispatch('cart/removeFromCart', payload).then(() => {
        this.cartInfo
        this.getAllLists()
      })
    },

    moveToFav (cartItem) {
      if (!this.isLoggedIn) {
        this.openModal = true
      }

      const payload = {
        app_version: '',
        store: '',
        token: this.getUserToken,
        offer_id: cartItem.offer.offer_id
      }

      ToggleWishList.toggleWishList(payload)
        .then(() => {
          this.removeFromCart(cartItem)
          this.cartInfo
        })
        .catch(() => {})
    },

    setOfferId (id, name) {
      const offerName = name.replace(/[ "" 25%]/g, '-')
      const path = {
        name: 'offer-details',
        params: { id, offerName }
      }
      if (this.$route.path !== path) this.$router.push(path)
    },

    toggle (inputId) {
      this.form[inputId] = !this.form[inputId]
      if (this.form.useFromWallet) {
        this.total_requerd_after_using_wallet =
          this.cartInfo[2].value.replace('جنيه', '').replace('EGP', '') -
          this.userInfo.users_balance
        this.isWallet = true
        this.isPromoCodeSuccess = false
      } else {
        this.isWallet = false
      }
    },

    goToPayment () {
      if (this.isLoggedIn) {
        if (this.form.useFromWallet) {
          localStorage.removeItem('PaymentId')
          if (this.total_requerd_after_using_wallet != 0) {
            this.$router.push({ name: 'PaymentMethods' })
          } else {
            this.payWithCredit()
          }
          // this.payWithCredit()
        } else {
          this.$router.push({ name: 'PaymentMethods' })
        }
      } else {
        this.openModal = true
      }
    },

    getCartQuantity () {
      this.$store.dispatch('cart/getGeneralData')
    },

    getUserInfo () {
      this.$store.dispatch('Globals/getUserInfo')
    },

    payWithCredit () {
      const payload = {
        user_id: this.userInfo.users_id,
        confirm_phone: this.userPhone,
        payment_method: 1,
        app_version: '',
        store: '',
        device_token: this.getUserIp,
        credit: this.cartInfo[2].value.replace('جنيه', '').replace('EGP', ''),
        fawry_type: 'fawry',
        discount_code: this.form.coupon || null
      }

      CompletePayment.completePayment(payload)
        .then((res) => {
          localStorage.setItem('order_id', res.data.data.order_id)
          this.getCartQuantity()
        })
        .catch((err) => {})
    },

    validateData () {}
  }
}
</script>

<style lang="scss" scoped>
@import './cart';
</style>
