<template>
  <div class="w-100">
    <Cart />
  </div>
</template>

<script>
import Cart from '@/components/profile/cart/Index.vue'
export default {
  name: 'CartPage',
  components: { Cart }
}
</script>
