<template>
  <div class="counter-block">
    <div
      class="icon cursor-pointer"
      @click="incrementQuantity(), addToCart(cartList)"
    >
      <PlusIcon />
    </div>

    <span class="m-x-6 fs-20 font-weight-500 p-t-2">
      {{ initialCounter }}
    </span>
    <div
      v-if="isActive"
      class="icon cursor-pointer"
      @click="decrementQuantity(), removeFromCart(cartList)"
    >
      <MinusIcon />
    </div>

    <div
      v-if="!isActive"
      class="icon icon-minus cursor-pointer"
    >
      <MinusIcon />
    </div>
  </div>
</template>
<script>
import PlusIcon from '@/components/icons/PlusIcon'
import MinusIcon from '@/components/icons/MinusIcon'
export default {
  name: 'CartCounter',
  components: {
    PlusIcon,
    MinusIcon
  },

  props: {
    counter: {
      type: [String, Number],
      required: true
    }
  },

  data () {
    return {
      isActive: false,
      initialCounter: this.counter,
      cartList: {}
    }
  },

  created () {
    this.isActiveCounter()
  },

  methods: {
    incrementQuantity () {
      this.initialCounter++
      this.isActiveCounter()
      this.$emit('getCounter', this.initialCounter)
    },

    decrementQuantity () {
      this.initialCounter--
      this.isActiveCounter()
      this.$emit('getCounter', this.initialCounter)
    },

    isActiveCounter () {
      if (this.initialCounter > 1) {
        this.isActive = true
      } else {
        this.initialCounter = 1
        this.isActive = false
      }
    },

    addToCart (cartList) {
      this.$emit('onAddToCart', cartList)
    },

    removeFromCart (cartList) {
      this.$emit('onRemoveFromCart', cartList)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './base-order-card';
</style>
