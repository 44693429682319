<template>
  <div>
    <div
      class="order-card d-flex justify-content-between align-items-center radius-12 p-12 m-b-16"
      :class="{ 'is-cart': isCart }"
    >
      <div class="order-info d-flex justify-content-start align-items-center">
        <div class="img-container radius-6 m-r-10">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class=""
            type="rect"
            :width="200"
            :height="200"
            animation="wave"
          /> -->
        </div>

        <div class="content">
          <h4 class="">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="radius-6 m-auto m-md-0"
              type="rect"
              :width="150"
              :height="25"
              animation="wave"
            /> -->
          </h4>
          <p class="fs-14 light-gray-color-text m-y-10">
            <!-- <VueSkeletonLoader
              v-show="offerLoading"
              class="radius-6"
              type="rect"
              :width="190"
              :height="25"
              animation="wave"
            /> -->
          </p>
          <div
            v-if="!isCart"
            class="d-flex justify-content-start align-items-center gap-24 num-of-coupons"
          >
            <span class="">
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-6"
                type="rect"
                :width="50"
                :height="25"
                animation="wave"
              /> -->
            </span>
            <span class="">
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-6"
                type="rect"
                :width="50"
                :height="25"
                animation="wave"
              /> -->
            </span>
          </div>
        </div>
      </div>

      <div class="order-action">
        <div
          class="badgs d-flex justify-content-end align-items-center gap-10 m-b-20"
        >
          <div class="p-y-4 p-x-8">
            <span class="fs-12 medium-font white-color-text">
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-6"
                type="rect"
                :width="50"
                :height="20"
                animation="wave"
              /> -->
            </span>
          </div>

          <div class="p-y-4">
            <span class="fs-12 medium-font font-weight-600 main-color-text">
              <!-- <VueSkeletonLoader
                v-show="offerLoading"
                class="radius-6"
                type="rect"
                :width="50"
                :height="20"
                animation="wave"
              /> -->
            </span>
          </div>
        </div>

        <div
          class="actions d-flex justify-content-end align-items-center gap-10"
        >
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="radius-6"
            type="rect"
            :width="140"
            :height="30"
            animation="wave"
          /> -->

          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            class="radius-6"
            type="rect"
            :width="140"
            :height="30"
            animation="wave"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'BaseOrderLoader',
  // components: { VueSkeletonLoader },
  props: {
    isCart: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>
<style lang="scss" scoped>
@import './base-order-card.scss';
</style>
