<template>
  <div class="cart-empty p-y-70 p-x-20">
    <div class="text-center mb-5">
      <img
        data-v-a852ec22=""
        src="/img/download-app.ebe16e10.png"
        style="max-width: 200px"
      >
    </div>
    <h2 class="text-center fs-26 mb-5 font-weight-bolder black-color-text">
      {{ $t('account.emptyCart') }}
    </h2>
    <p class="text-center fs-14 light-gray-color-text description w-60 m-auto">
      {{ $t('account.text') }}
    </p>
    <div class="text-center">
      <div class="m-t-40 d-flex justify-content-center align-items-center">
        <base-button
          colorClass="white-color-text"
          bgColorClass="main-color-bg"
          class="text-center"
          :text="'offers.browseOffers'"
          is-large
          isRoute
          :routeUrl="{ name: 'new-offers' }"
          fixed-width="250px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyCart',
  components: {},

  data () {
    return {}
  },
  computed: {},

  methods: {}
}
</script>

<style lang="scss" scoped>
@import './cart';
</style>
