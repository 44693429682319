<template>
  <div class="loader" />
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="sass" scoped>
@import "./loader"
</style>
