<template>
  <div class="is-cart order-card radius-12 p-12 m-b-16">
    <div class="order-info d-flex justify-content-start align-items-center">
      <div class="order-block">
        <!-- Add To Cart --->
        <div class="coupon radius-4 main-color-bg-with-opacity">
          <span class="fs-12 medium-font font-weight-600 main-color-text">
            {{ cartList.offer.offer_type }}
          </span>
        </div>

        <cartCounter
          :counter="cartList.qty"
          @getCounter="getCounter"
          @onAddToCart="addToCart(cartList)"
          @onRemoveFromCart="removeFromCartCounter(cartList)"
        />
      </div>

      <div class="img-container radius-6 m-r-10">
        <!-- for disccustion --->
        <!-- <div class="black-color-bg p-y-4 p-x-8 radius-4 price-block">
            <span class="fs-12 medium-font white-color-text">
              {{ cartList.price }} {{ $t('account.egp') }}
            </span>
          </div> -->

        <img
          class="cursor-pointer"
          :src="cartList.offer.offer_image"
          :alt="cartList.offer.offer_name"
          @click="sendOfferId(cartList.offer.offer_id)"
        >
      </div>

      <div class="content">
        <h4
          class="bold-font fs-16 black-color-text title cursor-pointer"
          @click="sendOfferId(cartList.offer.offer_id)"
        >
          {{ cartList.offer.offer_name }}
        </h4>

        <p class="fs-14 fs-sm-12 light-gray-color-text m-y-10 description">
          {{ cartList.offer.offer_brief }}
        </p>

        <div
          class="d-flex justify-content-start align-items-center gap-24 num-of-coupons"
        >
          <span class="black-color-text fs-14 medium-font">
            {{ cartList.purchase_no_coupons }}
          </span>
          <span class="black-color-text fs-14 medium-font main-color-text">
            {{ cartList.order_num }}
          </span>
        </div>
      </div>
    </div>

    <div class="order-action">
      <div
        class="badgs d-flex justify-content-end align-items-center gap-10 m-b-20"
      />

      <div class="actions d-flex justify-content-end align-items-center gap-10">
        <template>
          <div class="black-color-bg m-x-28 radius-4 price-block-sm">
            <span class="fs-12 medium-font white-color-text">
              {{ cartList.price }} {{ $t('account.egp') }}
            </span>
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <TrashIcon class="trashIcon" />
            <BaseButton
              :text="$t('account.remove')"
              fixedWidth="50px"
              :is-small="true"
              bgColorClass="white-color-bg"
              colorClass="red-color-text"
              @click.native="confirmRemoveFromCart = true"
            />
          </div>

          <div class="d-flex align-items-center justify-content-center">
            <HeartLineIcon class="heartIcon" />
            <BaseButton
              :text="'account.addToFav'"
              fixedWidth="100px"
              :is-small="true"
              bgColorClass="white-color-bg"
              colorClass="light-gray-color-text"
              @click.native="confirmMovToFav = true"
            />
          </div>
        </template>
      </div>
    </div>

    <!--Confirm Remove-->
    <BaseModal
      v-if="confirmRemoveFromCart"
      @close="hideModalPopup()"
    >
      <h1>{{ $t('home.removeFromFavTitle') }}</h1>
      <div
        class="d-flex justify-content-center align-items-center bold-font m-t-20"
      >
        <span
          class="cursor-pointer m-x-16"
          @click="removeFromCart(cartList)"
        >{{
          $t('account.remove')
        }}</span>

        <span
          class="main-color-text cursor-pointer m-x-16"
          @click="confirmRemoveFromCart = false"
        >{{ $t('account.cancel') }}</span>
      </div>
    </BaseModal>

    <!--Confirm Move To Favourite-->
    <BaseModal
      v-if="confirmMovToFav"
      @close="hideMovToFavModalPopup()"
    >
      <h1>{{ $t('home.moveToFavTitle') }}</h1>
      <div
        class="d-flex justify-content-center align-items-center bold-font m-t-20"
      >
        <span
          class="cursor-pointer m-x-16"
          @click="moveToFav(cartList)"
        >{{
          $t('account.addToFav')
        }}</span>

        <span
          class="main-color-text cursor-pointer m-x-16"
          @click="confirmMovToFav = false"
        >{{ $t('account.cancel') }}</span>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import HeartLineIcon from '../../icons/HeartLineIcon.vue'
import TrashIcon from '../../icons/trash.vue'
import cartCounter from './cartCounter'
import BaseModal from '../base-modal/Index.vue'

export default {
  name: 'CartCard',
  components: { HeartLineIcon, TrashIcon, cartCounter, BaseModal },
  props: {
    lists: {
      type: Array,
      required: false,
      default: () => []
    },
    cartList: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },

  data () {
    return {
      isFav: true,
      payload: {},
      confirmRemoveFromCart: false,
      confirmMovToFav: false
    }
  },
  computed: {
    cartQuantity () {
      return this.$store.state.cart.cartQuantity
    },
    isLoggedIn () {
      return this.$store.getters['Globals/checkUserLogin']
    }
  },

  methods: {
    getCounter (el) {
      this.counter = el
    },

    getAllLists () {
      const payload = {
        app_version: '',
        store: '',
        device_token: this.getUserIp
      }

      this.$store
        .dispatch('cart/getAllCarts', payload)
        .then(() => {})
        .catch((err) => {})
    },

    addToCart (cartList) {
      this.payload = {
        'items[0][offer_id]': cartList.offer.offer_id,
        'items[0][type_id]': cartList.type_id,
        'items[0][qty]': 1,
        'device_token': this.getUserIp
      }
      if (!this.isLoggedIn) {
        this.payload = {
          ...this.payload
        }
      } else {
        this.payload = {
          ...this.payload,
          token: this.getUserToken
        }
      }

      this.$store.dispatch('cart/addToCart', this.payload).then(() => {
        this.getAllLists()
      })
    },

    removeFromCartCounter (cartList) {
      const payload = {
        'items[0][offer_id]': cartList.offer.offer_id,
        'items[0][type_id]': cartList.type_id,
        'items[0][qty]': -1,
        'device_token': this.getUserIp
      }
      this.$store.dispatch('cart/addToCart', payload).then(() => {
        this.getAllLists()
      })
    },

    removeFromCart (offerObj) {
      this.$emit('onRemoveCart', offerObj)
    },

    moveToFav (offerObj) {
      this.isFav = false
      this.$emit('onMoveToFav', offerObj)
    },

    sendOfferId () {
      this.$emit(
        'goToOffer',
        this.cartList.offer.offer_id,
        this.cartList.offer.offer_name
      )
    },

    hideModalPopup () {
      this.confirmRemoveFromCart = false
    },

    hideMovToFavModalPopup () {
      this.confirmMovToFav = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import './base-order-card.scss';
</style>
